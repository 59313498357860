import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/StarOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RewardIcon from '@mui/icons-material/EmojiEventsOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import TimelineIcon from '@mui/icons-material/Timeline';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HighlightIcon from '@mui/icons-material/Highlight';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import * as Sentry from "@sentry/react";

import { Copyright } from '../Copyright';
import { Recognition } from '../Teachers/Recognition/Recognition';
import { Feed } from '../Teachers/Feed/Feed';
import { PrivateRoute } from '../PrivateRoute';
import { Rewards } from '../Teachers/Rewards/Rewards';
import { TeachersSupport } from '../Teachers/TeachersSupport';
import Logo from '../../images/hilight_hi_logo.png';
import { UserSummary } from '../UserSummary';
import { Data } from '../Teachers/Data/Data';
import { Spotlight } from '../Teachers/Spotlight/Spotlight';
import { UserAccount } from '../Teachers/UserAccount/UserAccount';
import { School, UserData, UserKredsCount } from '../../../../src/types/user';
import { UserServiceApi } from '../../services/userService';
import { MainServiceApi } from '../../services/mainService';
import { TokenTypeDisplayData } from '../../../../src/types/recognition';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { authSelector, verifyTokenSilentAuth, userLogout, updateUserData } from '../../store/authSlice';
import { PermissionServiceApi } from '../../services/permissionService';
import { Permission } from '../../../../src/types/permissions';
import { AdminSettings } from '../Teachers/AdminSettings/AdminSettings';
import { UserType } from '../../util/type';
import PrivacyPolicyPopup from '../PrivacyPolicy/PrivacyPolicyPopup';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';

import './TeacherDashboard.css'

export interface TeacherDashboardProps {
	showMobileInstall: boolean;
	handleClickMobileInstall?: () => Promise<void>;
}

export default function TeacherDashboard(props: TeacherDashboardProps) {
	const {showMobileInstall, handleClickMobileInstall} = props;

	const navigate = useNavigate();
	const authDataStore = useAppSelector(authSelector);
	const dispatch =  useAppDispatch();
	const { user, token, expiresAt, isAuthenticated } = authDataStore;

	// Note - it should never get to this point with user undefined. If isAuthenticated is true, then user should always be defined, and the PrivateRoute component will never let it get here without isAuthenticated=true
	// So we can assume user! for the rest of this component
	if (!user) {
		Sentry.captureMessage('User undefined on TeacherDashboard - Should not happen');
	}

	const [selectedSchoolId, setSelectedSchoolId] = useState<number>(user!.primarySchoolId);
	const [school, setSchool] = useState<School | undefined>();
	const [userKreds, setUserKreds] = useState<UserKredsCount | undefined>();
	const [tokenTypes, setTokenTypes] = useState<TokenTypeDisplayData[]>([]);
	const [schoolStaffList, setSchoolStaffList] = useState<UserData[]>([]);
	
	// side bar state
	const [sideBarOpen, setSideBarOpen] = useState(!isMobile);
	function toggleSideBar(): void {
		setSideBarOpen(!sideBarOpen);
	}

	// privacy policy popup
	const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);
	const closePopup = () =>  {
		setShowPrivacyPopup(false);
	}
	const openPrivacyPopup = () => {
		setShowPrivacyPopup(true);
	}

	const userIsAdmin = user?.userType === UserType.Admin;
	
	// permissions
	const [hideRewardsPermissionEnabled, setHideRewardPermissionEnabled] = useState<boolean>(false);
	const [spotlightEnabled, setSpotlightEnabled] = useState<boolean>(false);

	// Set a timer to renew the auth token
	useEffect(() => {
		if (expiresAt) {
			// We need to refresh the token 10 seconds before it expires
			// setTimeout is a function that is used to call another function after the specified number of milliseconds
			// So we want this timer to call 10 seconds (10,000ms) before the expiration of the token (to give plenty of time to refresh the token)
			// So we need to get the difference in milliseconds between now and the expiration date
			// The next line is essentially difference in ms between the expiration date and now, and then minus and extra 10 seconds 
			
			const refreshTokenMilliseconds = moment(expiresAt).diff(moment()) - (10 * 1000); 
			const verifyTokenTimer = setTimeout(() => {
				dispatch(verifyTokenSilentAuth());
			}, refreshTokenMilliseconds);

			// When it's done, reset the timer
			return () => {
				clearTimeout(verifyTokenTimer);
			}
		}
		return;
		
	}, [expiresAt, token]);

	const handleClickLogout = async () => {
		await dispatch(userLogout());
		navigate('/login');
	}

	const handleSaveUserData = async (updatedUserData: {
		newFirstName?: string,
		newLastName?: string,
		newEmail?: string,
		newPrimarySchoolId?: number,
		newRoleAtSchool?: string,
		newProfilePhotoImagePath?: string | null
		schools?: School[]
	}) => {
		const userService = UserServiceApi();
		try {
			// Update the User Data
			const result: { user?: UserData} = await userService.updateUserData(
				user!.id, 
				{
					...updatedUserData
				},
				token!
			)
			
			if (result.user) {
				dispatch(updateUserData(result.user));
			}

		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

	const getUserPointsData = async () => {
		// re-get the user's kreds
		const userServiceApi = UserServiceApi();

		try {
			const userKredsCount = await userServiceApi.getUserKredsCount(user!.id, token!);
		
			if (userKredsCount) {
				setUserKreds(userKredsCount);
			} else {
				throw new Error('Unable to retrieve user kreds for user');
			}
			
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

	const getOtherSchoolInfo = async () => {
		const mainServiceApi = MainServiceApi();	
		try {

			// Get the list of token types for the school
			const tokenTypesList = await mainServiceApi.getTokenTypes(selectedSchoolId, token!);
			setTokenTypes(tokenTypesList || []);

			// Get the list of teachers for the school
			const schoolStaff = await mainServiceApi.getSchoolStaff(selectedSchoolId, token!);
			setSchoolStaffList(schoolStaff || []);
			
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
		
	}

	useEffect(() => {
		getUserPointsData();
	}, [user!.id, selectedSchoolId]);

	const getSchoolData = async (schoolId: number) => {
		const mainServiceApi = MainServiceApi();
		try {
			// Set the school (based on the school selected in the drop down if the user belongs to more than one)
			const currentSchool = user!.schools?.find((school) => school.id === schoolId);
			if (!currentSchool) {
				// TO DO - understand when this would happen? If for some reason user.schools isn't populated?
				const schoolData = await mainServiceApi.getSchool(schoolId, token!)
					if (schoolData) {
						setSchool(schoolData);
					}
			} else {
				setSchool(currentSchool);
			}
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

	useEffect(() => {
		getSchoolData(selectedSchoolId);
		
	}, [selectedSchoolId])

	useEffect(() => {
		getOtherSchoolInfo();	
	}, [selectedSchoolId]);


	// Permissions
	const getDistrictLevelPermissions = async (districtId: number, userType: UserType) => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);

			// Is Reward tab hidden for this district?
			const hideRewardsKey = 'HIDE_REWARDS';
			const districtHasHideRewards = districtPermissions.find(permission => permission.permissionKey === hideRewardsKey);
			if (districtHasHideRewards) {
				setHideRewardPermissionEnabled(true);
			} else {
                setHideRewardPermissionEnabled(false);
            } 

			// Is Spotlight tab enabled for this district?
			isSpotlightEnabled(districtPermissions, userType)
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

	useEffect(() => {
		if (user!.districtId) {
			getDistrictLevelPermissions(user!.districtId, user!.userType);
		}
	}, [user!.districtId]);

	const isSpotlightEnabled = (districtPermissions: Permission[], userType: UserType) => {
		// Is Spotlight tab only enabled for Admin for this distric?
		const spotlightEnabledAdminOnlyKey = 'SPOTLIGHT_ENABLED_ADMIN_ONLY';
		const districtHasSpotlightEnabledAdminOnly = districtPermissions.find(permission => permission.permissionKey === spotlightEnabledAdminOnlyKey);
		if (districtHasSpotlightEnabledAdminOnly) {
			if (userType === UserType.Admin) {
				setSpotlightEnabled(true);
			} else {
				setSpotlightEnabled(false);
			}
			return;
		} else {
			// Is Spotlight tab enabled for everyone in this district?
			const spotlightEnabledKey = 'SPOTLIGHT_ENABLED';
			const districtHasSpotlightEnabled = districtPermissions.find(permission => permission.permissionKey === spotlightEnabledKey);
			if (districtHasSpotlightEnabled) {
				setSpotlightEnabled(true);
				return;
			}
		} 	
		setSpotlightEnabled(false);	
	}

  	return (
		<Box className='base-page'>
			<CssBaseline />
			<PrivacyPolicyPopup popupOpen={showPrivacyPopup} closePopup={closePopup}/>
			<div className={`sidebar ${sideBarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
				<div className={`sidebar-top ${sideBarOpen ? '' : 'sidebar-top-closed'}`}>
					<Box
						component="img"
						className='sidebar-logo'
						alt="hilight logo."
						src={Logo}
					/>
					{school && sideBarOpen ?
						(
							user!.schools?.length && user!.schools?.length > 1 ?
							<FormControl size='small' className='school-select-form-field' sx={{ m: 1, minWidth: 120 }}>
								<Select
									id='school-select'
									className='sidebar-school-name-select'
									value={selectedSchoolId}
									onChange={(event) => {
										setSelectedSchoolId(event.target?.value as number);
									}}
									sx={{
										color: "white",
										'.MuiOutlinedInput-notchedOutline': {
											borderColor: 'rgba(228, 219, 233, 0.25)',
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: 'rgba(228, 219, 233, 0.25)',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: 'rgba(228, 219, 233, 0.25)',
										},
										'.MuiSvgIcon-root ': {
											fill: "white !important",
										}
										}}
								>
									{user!.schools.map((school, index) => (
										<MenuItem key={school.id} value={school.id}>
											{school.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							:
							<div className='sidebar-school-name'>{school.name}</div>
						)
						: <></>
					}
					{isMobile &&
						<div className={`${sideBarOpen ? 'sidebar-toggle-open' : 'sidebar-toggle-closed'}`}>
							<IconButton className='sidebar-toggle-button' onClick={() => toggleSideBar()} >
								{sideBarOpen ?
									<ChevronLeftIcon className='sidebar-toggle-icon' id='toggle-icon-left'/>
									: <ChevronRightIcon  className='sidebar-toggle-icon' id='toggle-icon-right'/>
}
							</IconButton>
						</div>
					}
				</div>
				<div className='sidebar-user-summary' hidden={!sideBarOpen}>
					<UserSummary 
						user={user!} 
						userKreds={userKreds}
						rewardsDisabled={hideRewardsPermissionEnabled}
					/>
				</div>
				<div className='sidebar-nav'>
					<List>
						<div>
							<ListItem button onClick={() => navigate('/teacherDashboard/recognition')}>
								<ListItemIcon className='sidebar-list-item'>
									<StarIcon className="sidebar-icon" />
								</ListItemIcon>
								<ListItemText primary="Recognition" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							<ListItem button onClick={() => navigate('/teacherDashboard/feed')}>
								<ListItemIcon className='sidebar-list-item'>
									<FormatListBulletedIcon className="sidebar-icon" />
								</ListItemIcon>
								<ListItemText primary="Bulletin" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							{!hideRewardsPermissionEnabled && 
							<ListItem button onClick={() => navigate('/teacherDashboard/rewards')}>
								<ListItemIcon className='sidebar-list-item'>
									<RewardIcon className="sidebar-icon"/>
								</ListItemIcon>
								<ListItemText primary="Rewards"hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							}
							<ListItem button onClick={() => navigate('/teacherDashboard/data')}>
								<ListItemIcon className='sidebar-list-item'>
									<TimelineIcon className="sidebar-icon"/>
								</ListItemIcon>
								<ListItemText primary="Data" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							{spotlightEnabled &&
							<ListItem button onClick={() => navigate('/teacherDashboard/spotlight')}>
								<ListItemIcon className='sidebar-list-item'>
									<HighlightIcon className="sidebar-icon"/>
								</ListItemIcon>
								<ListItemText primary="Spotlight" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							}
							<ListItem button onClick={() => navigate('/teacherDashboard/useraccount')}>
								<ListItemIcon className='sidebar-list-item'>
									<PersonOutlinedIcon className="sidebar-icon"/>
								</ListItemIcon>
								<ListItemText primary="Account" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							<ListItem button onClick={() => navigate('/teacherDashboard/support')}>
								<ListItemIcon className='sidebar-list-item'>
									<HelpIcon className="sidebar-icon" />
								</ListItemIcon>
								<ListItemText primary="Support" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
							{userIsAdmin && 
								<ListItem button onClick={() => navigate('/teacherDashboard/adminsettings')}>
									<ListItemIcon className='sidebar-list-item'>
										<SettingsIcon className="sidebar-icon" />
									</ListItemIcon>
									<ListItemText primary="Admin Settings" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
								</ListItem>
							}
							<ListItem button onClick={handleClickLogout}>
								<ListItemIcon className='sidebar-list-item'>
									<LogoutIcon className="sidebar-icon"/>
								</ListItemIcon>
								<ListItemText primary="Logout" hidden={!sideBarOpen} className='side-bar-list-item-text'/>
							</ListItem>
						</div>
					</List>
				</div>
				{(sideBarOpen && isMobile && showMobileInstall) && 
					<div className='sidebar-install-mobile'>
						<div className='sidebar-install-text'>
							<Typography >Click here to install Hilight on your mobile device:</Typography>
						</div>
						<Button 
							onClick={handleClickMobileInstall} 
							variant='contained' 
							className='sidebar-install-mobile-button'
							size='small'
						>
							Install
						</Button>
					</div>
				}
			</div>
			<Box
			component="main"
			className='main-box'
			id='main-box' // used by ScrollToTop
			sx={{height: '100vh'}}
			>     
				<Routes>
					<Route path="recognition" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<Recognition 
								userId={user!.id}
								userKreds={userKreds}
								school={school} 
								handleRecognitionSubmitted={getUserPointsData}
								previousLoginDate={user!.previousLoginDate}
								schoolTokenTypes={tokenTypes}
								schoolStaff={schoolStaffList}
								rewardsPointsDisabled={hideRewardsPermissionEnabled}
							/>
						</PrivateRoute>
					}/>
					<Route path="feed" element={
						<PrivateRoute isAuthenticated={isAuthenticated}> 
							<Feed 
								schoolId={selectedSchoolId}
								userId={user!.id}
							/>
						</PrivateRoute>
					}/>
					<Route path="rewards" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<Rewards
								userId={user!.id} 
								schoolId={selectedSchoolId} 
								userKreds={userKreds}
								refreshDataAfterRewardRedeemed={getUserPointsData}
							/>
						</PrivateRoute>
					}/>
					<Route path="data" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<Data 
								isAdminUser={userIsAdmin}
								tokenTypes={tokenTypes}
								userId={user!.id}
								currentSchool={school}
							/>
						</PrivateRoute>
					}/>
					<Route path="spotlight" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<Spotlight 
								schoolId={selectedSchoolId}
								districtId={user!.districtId}
								userId={user!.id}
							/>
						</PrivateRoute>
					}/>
					<Route path="useraccount" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<UserAccount
								user={user!}
								userKreds={userKreds}
								handleSaveUserData={handleSaveUserData}
								rewardsDisabled={hideRewardsPermissionEnabled}
							/>
						</PrivateRoute>
					}/>
					<Route path="adminsettings" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<AdminSettings user={user!}/>
						</PrivateRoute>
					}/>
					<Route path="support" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<TeachersSupport/>
						</PrivateRoute>
					}/>
					<Route path="privacypolicy" element={
						<PrivateRoute isAuthenticated={isAuthenticated}>
							<PrivacyPolicy/>
						</PrivateRoute>
					}/>
				</Routes>
				<Box className='footer'>
					<Button className='privacy-policy-button' size='small' onClick={openPrivacyPopup}>Privacy Policy</Button>
					<Typography> | </Typography>
					<Copyright className='copyright'/>
				</Box>
			</Box>
		</Box>
				
			
  );
}