// KEEP UP TO DATE WITH src/types/user
export enum UserType {
    Student = 'Student',
    Teacher = 'Teacher',
    Admin = 'Admin',
    Super = 'Super',
	Free = 'Free'
}

// KEEP UP TO DATE WITH src/types/rewards
export enum SchoolOrDistrictReward {
    School = 'School',
    District = 'District'
}